import RatingService from "@/services/rating-service";
import { Rating } from "@/types/rating";
import { defineStore } from "pinia";

interface RatingStore {
  ratingById: Record<string, Rating>;
}

const ratingService = new RatingService("rating");

export const useRatingStore = defineStore("rating", {
  state: (): RatingStore => ({
    ratingById: {},
  }),
  actions: {
    async setRating(id: string, rating: Rating): Promise<Rating> {
      this.ratingById[id] = await ratingService.add(rating);

      return this.ratingById[id];
    },
    async verifyRating(requestId: string, toUserId: string): Promise<Rating> {
      if (!this.ratingById[requestId]) {
        this.ratingById[requestId] = await ratingService.verify(
          requestId,
          toUserId,
        );
      }

      return this.ratingById[requestId];
    },
  },
});
