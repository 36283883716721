import { Rating } from "@/types/rating";
import { handleError } from "@/utils/error";
import BaseService from "./base-service";

class RatingService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async add(rating: Rating): Promise<Rating> {
    try {
      return await this.post(`${this.getServiceContext}`, rating);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async verify(
    feedbackRequestId: string,
    toUserId: string,
  ): Promise<Rating> {
    try {
      return await this.get(
        `${this.getServiceContext}?feedbackRequestId=${feedbackRequestId}&toUserId=${toUserId}`,
      );
    } catch (error) {
      throw handleError(error);
    }
  }
}

export default RatingService;
